.gallery-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 70px;

  }
  
  .gallery-masonry {
    display: flex;
    margin-left: -10px;
    width: auto;
  }
  
  .gallery-column {
    padding-left: 10px;
    background-clip: padding-box;
  }
  
  .gallery-image {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
  }
  
  /* Lightbox Overlay */
  .lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .lightbox-container {
    width: 80%;
    max-width: 900px;
    padding: 50px;
    border-radius: 10px;
  }
  .gallery-item {
    position: relative;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .image-code {
    font-size: 14px;
    color: var(--lighttext-color);
  font-family: 'Bangla';

  }
  